.products-container{
    display: flex;
    flex-direction: column;
    gap: 80px;
    color: var(--midblue);
    text-align: center;
    padding-top: 100px;
}
.product-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.product-container-title{
    padding: 15px 0px;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1.5s, transform 1.5s;
   -webkit-text-stroke: 2px var(--midblue);
}
.product-nav-list{
    display: flex;
    width: 100%;
    border-top: 1px solid var(--midblue);
    border-bottom: 1px solid var(--lightgrey);
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1.5s, transform 1.5s;
}
.product-nav-item{
    width: 25%;
    font-size: 12px;
    text-align: center;
    padding: 15px 0px;
    cursor: pointer;
}
.product-nav-item.selected{
    border-bottom: 3px solid var(--midblue);
    font-size: 14px;
    width: 50%;
    font-weight: bolder;
}
.product-container-carousel{
   position: relative;
   padding-bottom: 150px;
   display: flex;
   flex-direction: column;
   gap: 20px;
   opacity: 0;
    transform: translateY(100px);
    transition: opacity 1.5s, transform 1.5s;
    margin-top: 30px;
}
.product-container-carousel.bags{
    padding-bottom: 0px;
}
.button-col-container{
    position: absolute;
    bottom: 40px;
    display: flex;
    width: 100%;
    justify-content: center;
}
.button-col{
    padding: 20px 10px;
    border: none;
    background-color: var(--green);
    color: var(--white);
    cursor: pointer;
    font-size: 14px;
    font-weight: 800;
}
.product-container-title.active{
    opacity: 1;
    transform: translateY(0);
}
.product-nav-list.active{
    opacity: 1;
    transform: translateY(0);
}
.product-container-carousel.active{
    opacity: 1;
    transform: translateY(0);
}
.big-nav-item-text{
    display: none;
}
.big-nav-item-text.always{
    display: block;
}
.contain-product{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.product-text{
    font-size: 14px;
    padding: 10px;
}
.image-product.geo{
    border-radius: 5px;
}
.product-container-contain.bags{
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s;
}
.product-container-contain.bags.active{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    opacity: 1;
    transform: translateY(0px);
}
.image-bags-products{
    width: 80%;
    max-width: 300px;
    max-height: 500px;
}
.image-bags-products > img{
    border: 1px solid var(--lightgrey);
    border-radius: 5px;
    width: 100%;
    max-height: 500px;
}
.text-bags-products{
    font-size: 16px;
    color: var(--midblue);
    max-width: 500px;
}

@media (min-width: 769px) {
    .product-text{
        font-weight: 800;
    }
}

@media (min-width: 1000px) {
    .products-container{
        padding-top: 140px;
        gap: 50px;
        width: 100%;
        max-width: 1400px;
        align-self: center;
    }
    .product-container-title{
        font-size: 34px;
        text-align: left;
    }
    .product-container-contain{
        display: flex;
        width: 100%;
    }
    .product-container-contain > .carousel-root{
        width: 70%;
    }
    .product-title{
        display: none;
    }
    .product-nav-list{
        flex-direction: column;
        width: 30%;
        min-width: 250px;
        border: none;
    }
    .product-nav-item{
        width: 80%;
        padding: 15px;
        font-size: 16px;
    }
    .product-nav-item.selected{
        font-size: 18px;
    }
    .product-nav-item.selected{
        width: 90%;
    }
    .small-nav-item-text{
        display: none;
    }
    .big-nav-item-text{
        display: block;
        width: 100%;
        text-align: left;
    }
    .product-container-carousel{
        margin-top: 0px;
        padding-bottom: 0px;
    }
    .product-text{
        width: 40%;
        height: 100%;
        text-align: left;
    }
    .image-product-container{
        width: 60%;
        max-width: 500px;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    .image-product-container.bags{
        max-width: 300px;
    }
    .button-col-container{
        position: relative;
        bottom: 0px;
        display: flex;
        justify-content: flex-start;
        padding-left: 50px;
    }
    .contain-product{
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }
    .product-container-contain.bags.active{
        flex-direction: row;
        max-width: 1200px;
        align-self: center;
        background-color: white;
        border: 1px solid var(--lightgrey);
        border-radius: 5px;
    }
    .bags-container{
        width: 100%;
        max-width: 1400px;
        align-self: center;
        font-weight: 800;
    }
}
@media (min-width: 1200px) {
    .product-text{
        font-size: 18px;
    }
    .button-col-container{
        padding-left: 100px;
    }
}