:root{
  --blue: #0b2447;
  --midblue: #19376d;
  --lightblue: #3a5e93;
  --turquise: #13b5ca;
  --white: #f6f6f6;
  --lightgrey: #d9d9d9;
  --green: #6DA34D;
  --lightgreen: #78b357;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
body{
  width: calc(100vw - (100vw - 100%));
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: var(--white);
}
#root{
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
main{
  min-height: calc(100vh - 400px);
  display: flex;
  flex-direction: column;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
}

@media (min-width: 1000px) {
  #root{
    width: calc(100% - 60px);
  }
}
@media (min-width: 1200px) {
  #root{
    width: calc(100% - 120px);
  }
}