.principal-carousel-container > .carousel-root{
    min-height: 600px;
    max-height: 650px;
    height: calc(100vh - 200px);
}
.principal-carousel-container > .carousel-root > .carousel.carousel-slider{
    min-height: 600px;
    max-height: 650px;
    height: calc(100vh - 200px);
}
.principal-carousel-container > .carousel-root > .carousel.carousel-slider > .slider-wrapper{
    min-height: 600px;
    max-height: 650px;
    height: calc(100vh - 200px);
}
.principal-carousel-container > .carousel-root > .carousel.carousel-slider > .slider-wrapper > .slider{
    min-height: 600px;
    max-height: 650px;
    height: calc(100vh - 200px);
}
.principal-carousel-container > .carousel-root > .carousel.carousel-slider > .slider-wrapper > .slider > .slide {
    min-height: 600px;
    max-height: 650px;
    height: calc(100vh - 200px);   
}
.slide {
    opacity: 0;
    transition: opacity 1s;
}
.carousel .slide.selected {
   opacity: 1;
}
.image-slide-container{
    min-height: 600px;
    max-height: 650px;
    height: calc(100vh - 200px);
    position: relative;
    overflow: hidden;
}
.slide > .image-slide-container > .image-slide{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.carousel .control-dots .dot{
    width: 15px;
    height: 15px;
}
.cover-slide{
    background-color: rgba(0, 0, 0, 0.502);
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 100px 0px;
    text-align: center;
    align-items: center;
}
.slide > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-title{
    width: 80%;
    text-align: center;
    font-size: 30px;
    color: var(--white);
    font-weight: 800;
    text-shadow: 0 1px 3px rgba(0,0,0,.25);
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s;
    -webkit-text-stroke: 2px var(--white);
}
.slide > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-text{
   font-size: 20px;
   color: var(--lightblue);
   font-weight: 800;
   opacity: 0;
   transform: translateX(-100%);
   transition: all 1.5s;
   background-color: var(--white);
   padding: 15px 30px;
   max-width: 300px;
   -webkit-text-stroke: .5px var(--lightblue);
}
.slide > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-button{
    padding: 15px;
    align-self: center;
    background-color: var(--green);
    border: none;
    color: var(--white);
    cursor: pointer;
    font-weight: 800;
    font-size: 15px;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 2s, transform 2s, background-color 0.5s;
}
.slide.selected  > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-title{
    opacity: 1;
    transform: translateY(0);
}
.slide.selected  > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-text{
    opacity: 1;
    transform: translateX(0);
}
.slide.selected  > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-button{
    opacity: 1;
    transform: translateY(0);
}
.slide.selected > .image-slide-container > .image-slide{
    height: 1100px;
    transition: all 8s;
}
.items-slide-ss{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.items-slide-bs{
    display: none;
}
.slide > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-button:hover{
    background-color: var(--lightgreen);
}

@media (min-width: 769px) {
    .slide > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-title{
        font-size: 40px;
    }
    .slide > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-button{
        font-size: 20px;
        padding: 20px 40px;
    }
}
@media (min-width: 1000px) {
    .cover-slide{
       flex-direction: row;
       padding: 0px;
    }
    .items-slide-ss{
        align-items: flex-start;
        align-self: flex-start;
        padding: 40px 0px;
        justify-content: flex-start;
        position: absolute;
    }
    .items-slide-bs{
        position: absolute;
        display: flex;
        background-color: transparent;
        width: 50%;
        height: 200px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        gap: 10px;
        right: 0;
    }
    .items-slide-bs > span{
        background-color: var(--turquise);
        font-size: 120px;
        color: var(--white);
        width: 100%;
        max-width: 400px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 40px 15px;
        transform: translateX(100%);
        opacity: 0;
        transition: all 1s;
    }
    .slide.selected  > .image-slide-container > .cover-slide > .items-slide-bs > span{
        transform: translateX(0);
        opacity: 1;
    }
    .items-slide-bs > span > span{
        font-size: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .slide  > .image-slide-container > .cover-slide > .items-slide-bs > p{
        opacity: 0;
        transform: translateX(100%);
        font-size: 24px;
        font-weight: bold;
        color: var(--white);
        text-align: left;
        background-color: var(--turquise);
        padding: 8px 30px;
        width: 80%;
        align-self: flex-end;
        transition: all 1s;
        max-width: 500px;
    }
    .slide.selected  > .image-slide-container > .cover-slide > .items-slide-bs > p{
        opacity: 1;
        transform: translateX(0);
    }
    .slide > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-title{
        font-size: 50px;
        text-align: left;
        padding-left: 40px;
        width: 100%;
    }
    .slide > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-button{
        position: absolute;
        bottom: 40px;
        left: 100px;
        padding: 20px;
    }
    .items-slide-bs > ul{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: var(--white);
        font-size: 24px;
        font-weight: bold;
        text-align: left;
        max-width: 450px;
        transform: translateX(100%);
        opacity: 0;
        transition: all 1s;
        gap: 5px;
    }
    .items-slide-bs > ul > li{
        background-color: var(--turquise);
        padding: 10px 20px;
    }
    .slide.selected  > .image-slide-container > .cover-slide > .items-slide-bs > ul{
        transform: translateX(0);
        opacity: 1;
    }
    .items-slide-bs > ul > li > .fa-whatsapp{
        border: none;
        background-color: transparent;
        display: flex;
        gap: 10px;
        color: var(--white);
        text-align: left;
        width: 100%;
        font-weight: bold;
    }
    .items-slide-bs > ul > li > p,
    .items-slide-bs > ul > li > .link-to-maps
    {
        text-align: left;
        width: 100%;
        font-size: 20px;
        color: var(--white);
    }
}
@media (min-width: 1400px) {
    .slide > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-title{
        font-weight: 1000;
        max-width: 60%;
    }
    .slide.selected > .image-slide-container > .image-slide{
        height: 1700px;
    }
    .slide > .image-slide-container > .cover-slide > .items-slide-ss > .cover-slide-text{
        max-width: 400px;
        -webkit-text-stroke: 1px var(--lightblue)
    }
}

