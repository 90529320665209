.contact-section{
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: var(--midblue);
    background: radial-gradient(circle at center, var(--white), var(--white), var(--white), var(--lightgrey) 20%, var(--white), var(--white), var(--white));
    padding: 100px 0px;
    max-width: 1400px;
    align-self: center;
    width: 100%;
  }
.contact-title{
    text-align: center;
    padding: 20px 0px;
}
.form-container{
  width: 100%;
  height: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1.5s, transform 1s;
  align-self: center;
}
.data-item{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.form-contact{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: center;
}
input[type='text'], [type='email']{
  height: 35px;
  border-radius: 5px;
  border: 1px solid rgb(212, 212, 223);
  width: 90%;
  align-self: center;
  padding-left: 10px;
  outline: none;
  font-size: 16px;
}
textarea{
  width: 90%;
  align-self: center;
  height: 300px;
  border-radius: 10px;
  border: 1px solid rgb(212, 212, 223);
  vertical-align: top;
  outline: none;
  resize: none;
  overflow-y: auto;
  padding: 5px;
  font-size: 16px;
}
.success-icon-container{
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.success-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4ceb8e;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; 
  animation: fadeEffect 2s ease-in-out forwards; 
}
.checkmark {
  font-size: 20px;
  color: #ffffff; 
}
.button-form{
  width: 150px;
  height: 35px;
  font-size: 18px;
  color: var(--white);
  background-color: var(--green);
  border-radius: 5px;
  border: none;
  align-self: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-form:hover{
    background-color: var(--lightgreen);
  }
  
  .info-contact.active{
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s, transform 1s;
  }
  .form-container.active{
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s, transform 1s;
  }
  .info-contact{
    align-self: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s;
    width: 100%;
  }
  .list-in-contact{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .list-in-contact > p{
    color: var(--lightblue);
  }
  .info-contact > .data-list{
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  .info-contact > .data-list > .data-item{
    justify-content: left;
    font-size: 14px;
    font-weight: 800;
  }
  .fa-whatsapp{
    cursor: pointer;
  }
  .info-contact > .data-list > .data-item > .fa-whatsapp{
    display: flex;
    align-items: center;
    color: var(--midblue);
    font-weight: 800;
    border: none;
    background-color: transparent;
    gap: 10px;
    font-size: 16px;
  }
  .info-contact > .data-list > .data-item > .fa-whatsapp > p{
    font-size: 16px;
  }
  .info-contact > .data-list > .data-item > .link-to-maps{
    color: var(--midblue);
  }

  .loader-form-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-form {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: var(--white) var(--white) transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader-form::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent var(--lightblue) var(--lightblue);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
      
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes fadeEffect {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @media (min-width: 1000px) {
    .contact-section{
      padding: 140px 0px;
      flex-direction: row;
      justify-content: center;
    }
    .info-contact{
      align-self: flex-start;
      padding-top: 0px;
    }
  }