header{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    top: 0;
    left: 0;
    z-index: 99;
    transition: all 0.3s;
    position: relative;
}
header.fixed{
  position: fixed;
  height: 80px;
  box-shadow: 0px 0px 10px 0px black;
}
.top-header{
  width: 100%;
  height: 20px;
  background-color: var(--white);
  display: none;
}
.header-container{
  background: linear-gradient(to right, var(--blue), var(--midblue), var(--lightblue));
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  transition: all 0.5s;
}
.header-container.bs-fixed{
  position: fixed;
  top: 0;
  height: 80px;
}
.logo-container{
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--white);
    font-size: 10px;
}
.logo-header-img{
    height: 70%;
}
.menu-hamburguer{
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
}
.menu-hamburguer > div{
    width: 100%;
    height: 2px;
    background-color: var(--white);
}
.menu-bs{
    display: none;
}
.header-nav{
position: fixed;
top: 0;
left: 0;
width: 100%;
z-index: 99;
transform: translateY(-100%);
transition: all 0.1s;
}
.header-nav.active{
transform: translateY(0);
transition: all 1s;
}
.header-container.nav{
  height: 70px;
}

@media (min-width: 360px) {
    .logo-container{
        font-size: 14px;
    }
}
@media (min-width: 1000px) {
  header{
    height: 140px;
  }
  .logo-container{
    font-size: 20px;
    font-weight: 800;
  }
  .top-header{
    height: 20px;
    display: block;
  }
  .header-container{
    height: 140px;
  }
  .menu-hamburguer{
    display: none;
  }
  .menu-bs{
    display: block;
  }
  .menu-bs > .nav-list{
    display: flex;
    gap: 30px;
    font-size: 20px;
    font-weight: 800;
    color: var(--white);
    padding: 0px 30px;
  }
  .menu-bs > .nav-list > .nav-item{
    cursor: pointer;
  }
  .menu-bs > .nav-list > .nav-item:hover{
    color: var(--lightgrey);
  }
}