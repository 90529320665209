.company-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--midblue);
    padding: 100px 0px;
}
.img-company-container{
    width: 100%;
    max-width: 600px;
    height: 500px;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1.5s, transform 1s;
}
.img-company{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1.5s, transform 1s;
    border-radius: 5px;
    max-width: 700px;
}
.text-company-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1.5s, transform 1s;
}
.company-title{
    text-align: center;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1.5s, transform 1s;
    padding: 15px 0px 15px 10px;
    font-size: 34px;
   -webkit-text-stroke: 2px var(--midblue);
}
.company-title.active{
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s, transform 1s;
}
.img-company-container.active{
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s, transform 1s;
}
.img-company.active{
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s, transform 1s;
}
.text-company-section.active{
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s, transform 1s;
}
.company-contain{
    width: 90%;
    max-width: 1400px;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ch-opt-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 50px;
    opacity: 0;
   transform: translateY(100px);
   transition: all 1s;
}
.ch-opt-section > ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
}
.ch-opt-section > ul > li > span{
    font-size: 28px;
    color: var(--lightgreen);
    margin-right: 10px;
}
.ch-opt-section > ul > li > strong{
    font-size: 16px;
    color: var(--midblue);
   -webkit-text-stroke: 1px var(--midblue);
}
.ch-opt-section > ul > li > p{
    font-size: 16px;
    color: var(--lightblue);
   -webkit-text-stroke: 0px;
}
.img-comp2{
    max-width: 400px;
    width: 100%;
    border-radius: 5px;
}
.ch-opt-title{
    text-align: center;
    padding: 15px 0px 15px 10px;
    font-size: 34px;
   -webkit-text-stroke: 2px var(--midblue);
   opacity: 0;
   transform: translateY(100px);
   transition: all 1s;
   color: var(--midblue);
}
.ch-opt-section.active{
    opacity: 1;
    transform: translateY(0);
}
.ch-opt-title.active{
    opacity: 1;
    transform: translateY(0);
}
@media (min-width: 769px) {
    .ch-opt-section{
        flex-direction: row;
    }
    .ch-opt-section > ul > li > span{
        font-size: 32px;
    }
}
@media (min-width: 1000px) {
    .company-section{
        padding: 140px 0px;
    }
    .company-contain{
        flex-direction: row;
        justify-content: center;
    }
    .img-company-container{
        width: 60%;
        max-width: 700px;
    }
    .img-company{
        width: 100%;
    }
    .text-company-section{
        width: 40%;
        font-size: 18px;
    }
    .ch-opt-section > ul > li > strong{
        font-size: 18px;
    }
    .ch-opt-section > ul > li > p{
        font-size: 18px;
    }
}