footer{
    background: linear-gradient(to right, var(--blue), var(--midblue), var(--lightblue));
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.top-footer{
    color: var(--white);
    display: flex;
    height: 100%;
    justify-content: center;
    gap: 5px;
}
.nav-footer{
    width: 40%;
    font-size: 18px;
    display: grid;
    place-items: center;
}
.nav-footer > .nav-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.nav-footer > .nav-list > .nav-item{
    cursor: pointer;
}
.data-footer{
    width: 60%;
    font-size: 11px;
    display: grid;
    place-items: center;
}
.data-footer > .data-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.data-footer > .data-list > .data-item{
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}
.data-footer > .data-list > .data-item > p, 
.data-footer > .data-list > .data-item > button,
.data-footer > .data-list > .data-item > .link-to-maps
{
    text-align: right;
    width: 100%;
    font-size: 10px;
    color: var(--white);
}
.data-footer > .data-list > .data-item > .large-p{
    font-size: 9px;
}
.bottom-footer{
    width: 100%;
    background-color: var(--white);
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: var(--blue);
}
.logo-footer{
    height: 60%;
}
.effebe-mark{
    font-size: 11px;
}
.effebe-mark > strong{
    font-style: italic;
}
.data-footer > .data-list > li > .whatsapp{
  display: flex;
  justify-content: center;
}
.data-footer > .data-list > li > .fa.fa-whatsapp{
    font-size: 20px;
    background-color: transparent;
    border: none;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}
.data-footer > .data-list > li > .fa.fa-whatsapp > p{
    font-size: 11px;
}

@media (min-width: 340px) {
    .data-footer > .data-list > .data-item{
        flex-direction: row;
        justify-content: flex-end;
    }
    .data-footer > .data-list > li > .fa.fa-whatsapp{
        flex-direction: row;
        justify-content: flex-end;
    }
}

@media (min-width: 360px) {
    .data-footer > .data-list > .data-item{
        flex-direction: row;
        justify-content: flex-start;
    }
    .data-footer > .data-list > .data-item > .link-to-maps{
        text-align: left;
    }
    .data-footer > .data-list > li > .fa.fa-whatsapp{
        flex-direction: row;
        justify-content: flex-start;
    }
}

@media (min-width: 435px){
    .data-footer > .data-list > .data-item > .large-p{
        font-size: 10px;
    }
    .data-footer > .data-list > .data-item > p, 
.data-footer > .data-list > .data-item > button,
.data-footer > .data-list > .data-item > .link-to-maps
{
    text-align: left;
    width: 100%;
    font-size: 12px;
}
.nav-footer,
.data-footer{
    width: 50%;
}
}
  