.banner-container{
    width: 70%;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 130px;
}
.banner-item{
    width: 100%;
    max-height: 250px;
    color: var(--midblue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 20px 10px;
    gap: 5px;
    border-bottom: 1px solid var(--blue);
    border-top: 1px solid var(--blue);
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s;
}
.banner-item.active{
    opacity: 1;
    transform: translateY(0);
}
.banner-item-img{
    width: 100%;
    max-width: 200px;
}
.banner-item-text{
    font-size: 14px;
    text-align: center;
    width: 100%;
    max-width: 200px;
    font-weight: 800;
}

@media (min-width: 769px) {
    .banner-container{
        flex-direction: row;
        width: 100%;
        gap: 0;
    }
    .banner-item{
        border: none;
    }
    .banner-item-img{
        max-width: 300px;
    }
    .banner-item-text{
        font-size: 18px;
    }
}