.menu-container{
    position: fixed;
    width: 70%;
    top: 0;
    right: 0;
    max-width: 400px;
    height: calc(100vh - (100vh - 100%));
    background: linear-gradient(to bottom, var(--midblue), var(--lightblue), var(--midblue));
    z-index: 100;
    translate: 0 0;
    transition: 500ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.menu-container.closed{
    visibility: hidden;
    opacity: 0;
    translate: 200% 0;
}
.top-menu{
    position: relative;
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.close-menu{
    color: var(--white);
    font-size: 30px;
    cursor: pointer;
}
.close-menu:hover{
    color: var(--lightgrey);
}
.first-mid-menu > .nav-list{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    padding: 15px;
}
.first-mid-menu > .nav-list > .nav-item{
    color: var(--white);
    font-size: 20px;
    cursor: pointer;
}
.first-mid-menu > .nav-list > .nav-item:hover{
    color: var(--lightgrey);
}
.bottom-menu{
    color: var(--white);
}
.bottom-menu > .data-list{
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.bottom-menu > .data-list > .data-item {
    font-size: 18px;
}
.bottom-menu > .data-list > .data-item > p,
.bottom-menu > .data-list > .data-item > .link-to-maps
{
    text-align: center;
    font-size: 12px;
    color: var(--white);
}
.bottom-menu > .data-list > li {
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottom-menu > .data-list > li  > .fa.fa-whatsapp{
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    border: none;
    color: var(--white);
}
.bottom-menu > .data-list > li  > .fa.fa-whatsapp > p{
    font-size: 12px;
}
.backdropPopUp{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fade-in 300ms forwards;
    background-color: rgba(0, 0, 0, 0.605);
    z-index: 90;
}

@media (min-width: 1000px) {
    .menu-container{
        display: none;
    }
}